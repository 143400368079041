import { module as ModuleJS } from "modujs";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class extends ModuleJS {
    constructor(m) {
        super(m);

        this.numbers = this.$("number");
        this.triggerEl = this.$("trigger")[0];
    }

    init() {
        this.setupAnimations();
        this.bindEvents();
    }

    setupAnimations() {
        this.numbers.forEach((numberEl) => {
            const isNumeric = Boolean(numberEl.dataset.isNumeric);
            if (!isNumeric) return;

            const increment = Number(numberEl.dataset.increment);
            const decimals = Number(numberEl.dataset.decimals);
            const html = numberEl.innerHTML;
            const value = numberEl.dataset.numberValue;
            const animationTrigger = this.triggerEl || numberEl;

            // wrapping number and string
            // match digit including commas or dots
            const numMatches = value.match(/[\d\.\,]+/);
            const num = Number(numMatches[0]);
            const numEl = document.createElement("span");
            numEl.className = "number-int";
            numEl.append(num);

            // const newHtml =
            //     '<span class="number-str">' +
            //     html
            //         .replace(num, "")
            //         .replace("€", '<span class="number-currency">€</span') +
            //     "</span>";
            const newHtml =
                '<span class="number-str">'
                "</span>";

            numberEl.innerHTML = newHtml;
            console.log(newHtml);
            numberEl.prepend(numEl);

            function numberWithCommas(x) {
                // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return x.toString();
            }

            let start = 0;
            if (increment < 1) {
                start = "0.";
                for (let i = 0; i < decimals; i++) {
                    start += "0";
                }
            }

            gsap.from(numEl, {
                scrollTrigger: {
                    trigger: animationTrigger,
                    start: "bottom bottom",
                },
                textContent: start,
                duration: 1.5,
                snap: { textContent: increment },
                stagger: {
                    each: 1.0,
                    onUpdate: function () {
                        if (increment < 1 && !this.targets()[0].textContent.includes(".")) {
                            this.targets()[0].textContent += ".";
                            
                            for (let i = 0; i < decimals; i++) {
                                this.targets()[0].textContent += "0";
                            }
                        }
                        this.targets()[0].innerHTML = numberWithCommas(
                            (this.targets()[0].textContent)
                        );
                    },
                },
            });
        });
    }

    bindEvents(el) {}

    update(newContainer) {}

    destroy(oldContainer) {}
}
