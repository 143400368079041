import { module as ModuleJS } from "modujs";
import MicroModal from 'micromodal'; // https://micromodal.vercel.app/#usage

const now = Date.now() / 1000; // seconds
// 2 days
const rememberDuration = 60 * 60 * 24 * 2; // seconds

const config = {
    onShow: function(modal) {
        if (modal.onModalShow && typeof modal.onModalShow === "function") {
            modal.onModalShow()
        }
    },
    onClose: function(modal) {
        if (modal.onModalClose && typeof modal.onModalClose === "function") {
            modal.onModalClose()
        }
    },
    // openTrigger: 'data-custom-open',
    // closeTrigger: 'data-custom-close',
    openClass: 'is-open',
    disableScroll: true,
    disableFocus: true,
    awaitOpenAnimation: false,
    awaitCloseAnimation: false,
    debugMode: true
}

export default class extends ModuleJS {
    constructor(m) {
        super(m);

        window.isModalInit = window.isModalInit || false;

        this.id = this.el.id;
        this.storageKey = `modal_seen_${this.id}`;

        this.openOnLoad = this.getData('open');
        this.remember = this.getData('remember');
        this.alreadySeen = this.checkSeen();
        this.shouldOpen = this.checkShouldOpen();

        this.active = false;
    }

    init() {
        this.setSeen = this.setSeen.bind(this);
        this.show = this.show.bind(this);
        this.onShow = this.onShow.bind(this);
        this.onClose = this.onClose.bind(this);

        this.el.modalConfig = config;
        this.el.onModalShow = this.onShow;
        this.el.onModalClose = this.onClose;

        if (this.id && this.shouldOpen) {
            this.show();
        }

        this.bindEvents();
    }

    show() {
        MicroModal.show(this.id, config);
    }

    onShow() {
        this.active = true;
        if(this.isMobile()){
            console.log('onmobile');
        }else {
            $(".search__input-text").trigger('focus');
            console.log('notmobile');
        }
        console.log('onShow');
        this.setSeen();
    }

    isMobile(){
        return /Mobi|Android/i.test(navigator.userAgent);
    }

    onClose() {
        this.active = false;
        $('.search__input-text').val(null);
        $(".search__result").html("");
        $(".search__all-result").hide();
        $(".search__no-result").hide();
        $(".search__info").show();
    }

    checkShouldOpen() {
        if (this.openOnLoad) {
            if (this.remember) {
                return !this.alreadySeen;
            }

            return true;
        }

        return false;
    }

    checkSeen() {
        const storedTime = localStorage.getItem(this.storageKey);
        if (storedTime) {
            const isSeenTimeExpired = parseInt(storedTime) <= now;
            return !isSeenTimeExpired;
        }

        return false;
    }

    setSeen() {
        if (this.remember) {
            const seenTime = now + rememberDuration;
            localStorage.setItem(this.storageKey, parseInt(seenTime));
        }
    }

    noop() {}

    bindEvents(el) {
    }
}
