// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// init Swiper:
const swiper = new Swiper('.swiper', {
    grabCursor: true,
    direction: "horizontal",
    slidesPerView: 1,
    autoplay: {
        delay: 2000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
    },
    loop: true,
    // modules: [Navigation, Pagination],
    modules: [Navigation, Autoplay],
  
    // If we need pagination
    // pagination: {
    //     el: '.swiper-pagination',
    // },
  
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    on: {
        slideChange: function (el) {
          $('.swiper-slide').each(function () {
            var youtubePlayer = $(this).find('iframe').get(0);
            if (youtubePlayer) {
                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            }
          });
        },
      },
});