import { module as ModuleJS } from "modujs";

export default class extends ModuleJS {
    constructor(m) {
        super(m);
        
        this.target = this.getData('target');
        this.targetEl = document.getElementById(this.target);
    }

    init() {
        this.show = this.show.bind(this);
        
        if (this.targetEl) {
            this.bindEvents();
        }
    }

    show(e) {
        if (e && e.preventDefault) e.preventDefault();
        this.call('show', null, 'Modal', this.target);
    }

    bindEvents() {
        this.el.addEventListener('click', this.show);
    }
}