require('./pagination');
require('./slider');
require('./animation');
import MicroModal from 'micromodal';  // es6 module
import modular from "modujs";
import * as modules from "./_modules";

const app = new modular({
    modules: modules,
});

window.addEventListener('load', event => {
    init();
    $(window).on('scroll', function(){
       if($(window).scrollTop() != 0) {
           $('#header').addClass('scrolled');
       }else {
           $('#header').removeClass('scrolled');
       }
    });


    var sectionHeight = $('#section1').height();
    $('a[href="#"]').on('click', e => e.preventDefault());


    var search_modal= $('#search-modal');
    if (search_modal.length > 0) {
        var url = "/get-last-disease-case";
        $.ajax({
            url: url,
            type: "get",
        }).done(function(data){
            if (data) {
                var json = JSON.parse(data);
                if (Object.keys(json.diseases).length > 0) {
                    template_last_content(json.diseases, '.last-diseases');
                }
                if (Object.keys(json.cases).length > 0)  {
                    template_last_content(json.cases, '.last-cases');
                }
            }
        }).fail(function(jqXHR, ajaxOptions, thrownError){

        });
    }
    function template_last_content(json, block) {
        var el = $(block);
        if (Object.keys(json).length > 0) {
            var html = "";
            for (const item in json) {
                html += "<li>";
                html += "    <a href='" + json[item].url + "' title='Voir la fiche de cours " + json[item].name + "' class=''>"
                html +=         json[item].name;
                html += "   </a>";
                html += "</li>";
            }
            el.find('ul').html(html);
            el.show()

            return true;
        } else {
            el.hide();
            return false;
        }
    }
    // console.log(sectionHeight);
})

MicroModal.init({
    onClose: (modalEl) => {

        // Récupération des iframes contenues dans la modal
        const iframes = modalEl.querySelectorAll('iframe');
        iframes.forEach(iframe => {

            // Si l'iframe est de YouTube
            const src = iframe.getAttribute('src');
            if (src.includes('youtube')) {

                // On stop la vidéo
                iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
            }
        })

    }
});

function init() {
    app.init(app);
}

jQuery(document).ready(function($) {
    var cgucondition = $('#cgucondition').data('value');
    $('#valid_cgu').addClass('disabled');
    if(cgucondition){
        $("#cgucondition").removeClass("modal").addClass("hide");
    }else{
        $('#cgucondition').modal({backdrop:'static',keyboard:false, show:true});
    }
    $('#valid_cgu').click(function(){
        $("#cgucondition").attr("data-value", "true");
        /*envoyer à laravel ????*/
    });

    $('.modal-body').on('scroll', function() {
        var $this = $(this);
        var scrollTop = $this.scrollTop();
        var scrollHeight = $this[0].scrollHeight;
        var height = $this.height();
        //console.log(scrollHeight - (scrollTop + height));
        if (scrollHeight - (scrollTop + height) <= 40) {
            $('#valid_cgu').removeClass('disabled');
            $('.disclaimer').removeClass('not-down');
            $('.disclaimer').addClass('down');
            $('.disclaimer').html("En cliquant sur valider, je reconnais avoir pris connaissance des <a href='/conditions-generales-d-utilisation.html' target='_blank' style='color: #0b7ec4'>Conditions générales d'utilisations</a>, comprenant la politique sur le droit d'auteur et les dispositions RGPD de Radeos");
        }
    });
});

