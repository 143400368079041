$(function() {
    $(document).on('click', '.btn-pagination',function(event) {
        event.preventDefault();
        if (!$(this).hasClass('loading')) {
            var myurl = $(this).attr('href');
            var page = $(this).attr('href').split('page=')[1];
            $(this).addClass('loading');
            // console.log(myurl);
            getData(myurl, page);
        }
    });

    $(document).on('click', '.search__trigger',function(event) {
        trig_search($(this));
    });
    $(".search__input-text").on('keypress', function(event) {
        var key = event.which;
        if(key == 13 && $(event.target).hasClass("search__input-text")) {
            $('.search__trigger').trigger('click');
            return false;  
        }
    });
    $(document).on('click', '.so-modal_close', function(event) {
        MicroModal.close('search-modal');
    })

    $('.search__all-result').on('click', function() {
        var href = $(this).attr('href');
        document.location.replace(href);
    });


});

function trig_search(el) {
    var q = $('.search__input-text').val();
    console.log()
    if (!el.hasClass('loading') && q != "") {
        var myurl = el.attr('data-source') + "?key=" + q;
        el.addClass('loading');
        $(".search__result").html("");
        $(".search__all-result").hide();
        // console.log(myurl);
        if ($('.search__advanced_filter').length > 0) {
            $('.search__advanced_filter').hide();
        }
        if ($('.search__input-text').length > 0) {
            $('.search__input-text').attr('disabled', true);
        }
        // console.log(myurl); 
        getData(myurl, 0, "search");
    }
}

function getData(page, page_num, mode = "pagination"){
    var url = page;
    var next_page_num = parseInt(page_num) + 1;
    var next_url = page.replace("page=" + page_num,  "page=" + next_page_num);
    $.ajax({
        url: url + "&ajax=true",
        type: "get",
    }).done(function(data){
        var str = data;
        var json = JSON.parse(str);
        var btnPagination = $('.btn-pagination');
        var q = $('.search__input-text').val();
        console.log(json);
        
        if (mode == "pagination") {
            var list = json.list.data;
            if (Object.keys(list).length > 0) {
                for (const item in list){
                    // console.log(json.list.data[item]);
                    if (mode == "pagination") {
                        var old_html = $(".pagination__result").html();
                        if (typeof json.is_module_capsule !== 'undefined') {
                            var new_html = template_list_cas(json.list.data[item]);
                        } else {
                            var new_html = template_list_cours(json.list.data[item]);
                        }
                        $(".pagination__result").html(old_html + new_html);
                    } 
                };
                if (btnPagination.length > 0) {
                    $('.btn-pagination').attr('href', next_url);
                    $('.btn-pagination').removeClass('loading');
                }
            } else {
                if (btnPagination.length > 0) {
                    $('.btn-pagination').hide();
                }
                if ($('.search__info').length > 0) {
                    $('.search__info').show();
                }
            }
        } else {
            if ($('.search__advanced_filter').length > 0) {
                $('.search__advanced_filter').attr('href',  $('.search__advanced_filter').attr('href') + "&search_engine[diagnosis]=" + q);
            }
            
            if ($('.search__info').length > 0) {
                $('.search__info').hide();
            }
            if (json.total_result_count > 0) {
                // console.log(json);
                if ($('.search__no-result').length > 0) {
                    $('.search__no-result').hide();
                }
                for (const item in json.resultItem) {
                    // console.log(json.resultItem[item]);
                    var old_html = $(".search__result").html();
                    var new_html = template_search(json.resultItem[item]);
                    $(".search__result").html(old_html + new_html);
                }
                if ($('.search__all-result').length > 0) {
                    $('.search__all-result').show();
                    $('.search__all-result').text('Voir tous les résultats (' + json.total_result_count + ")");
                    $('.search__all-result').attr('href', "/les-cas-radeos/liste.html?search_engine[diagnosis]="+q);
                }
            } else {
                if ($('.search__no-result').length > 0) {
                    $('.search__no-result').show();
                }
                if ($('.search__all-result').length > 0) {
                    $('.search__all-result').hide();
                }
            }

            if ($('.search__trigger').length > 0) {
                $('.search__trigger').removeClass('loading');
            }
            if ($('.search__advanced_filter').length > 0) {
                $('.search__advanced_filter').show();
            }
            if ($('.search__input-text').length > 0) {
                $('.search__input-text').attr('disabled', false);
            }
        }
    }).fail(function(jqXHR, ajaxOptions, thrownError){
        if ($('.search__trigger').length > 0) {
            $('.search__trigger').removeClass('loading');
        }
        if ($('.search__no-result').length > 0) {
            $('.search__no-result').show();
        }
        if ($('.search__info').length > 0) {
            $('.search__info').hide();
        }
        if ($('.search__advanced_filter').length > 0) {
            $('.search__advanced_filter').show();
        }
        if ($('.search__input-text').length > 0) {
            $('.search__input-text').attr('disabled', false);
        }
        console.log('No response from server');
    });
}

function template_list_cours(item) {
    var html = "";
    if (typeof item.notation !== "undefined" && item.notation!= "0") {
        var notation = item.notation;
        var notation_size = (parseFloat(item.average_rating) / 2) * 20;
        var notation_label = notation + "/10 noté x fois";
    } else {
        var notation = 0;
        var notation_size = 0;
        var notation_label = "Soyez le premier à noter cette maladie !";
    }
    var title = item.name_fr;
    var updated_at = item.updated_at;
    var url = item.url;
    if (item.active_clinical_case) {
        var url_cas = item.active_clinical_case.clinical_case_active_link;
        var nb_cas = item.active_clinical_case.count;
        if (nb_cas == "1") {
            var url_cas_title = "Voir le cas associé";
        } else {
            var url_cas_title = 'Voir les ' + nb_cas + ' cas associés';
        }
    }
    

    html += '<div class="block-maladie">';
    html += '<div class="block-maladie__content">';
    html += '   <div class="block-maladie-left">';

    html += '       <span class="intro" data-id="' + item.id + '" onclick="window.location.href=\'' + url + '\'">' + title + '</span>';

    html += '       <div class="note-container" title="' + notation_label + '" data-notecas="' + item.id + '" data-noteurl onclick="window.location.href=\'' + url + '\'">Note ';
    html += '           <span data-note="' + notation + '" class="note-result" style="position:absolute;z-index:10;overflow:hidden;white-space:nowrap;width:' + notation_size + 'px">'; 
    html += '               <span class="note-full note"></span>';
    html += '               <span class="note-full note"></span>'
    html += '               <span class="note-full note"></span>'
    html += '               <span class="note-full note"></span>'
    html += '               <span class="note-full note"></span>'
    html += '           </span>';
    html += '           <span class="note-result">'; 
    html += '               <span class="note-empty note"></span>';
    html += '               <span class="note-empty note"></span>';
    html += '               <span class="note-empty note"></span>';
    html += '               <span class="note-empty note"></span>';
    html += '               <span class="note-empty note"></span>';
    html += '           </span>';
    html += '       </div>';

    html += '        <p class="infoComp"><i>Fiche de cours mise à jour le ' + updated_at + '</i></p>';

    html += '   </div>';

    html += '   <div class="block-maladie-right">';
    html += '       <a href="' + url + '" class="block-maladie__link btn btn-primary tw-rounded-none tw-py-4 tw-block tw-text-[14px]">Voir la fiche</a>'
    if (item.active_clinical_case) {
    html += '       <a href="' + url_cas + '" class="btn btn-primary-light tw-rounded-none tw-py-4 tw-block tw-text-[14px]">' + url_cas_title + '</a>';
    }
    html += '   </div>';
    html += '</div>';
    html += '</div>'

    return html;
}
function template_list_cas(item) { 
    var url = item.url;
    var img_url = item.cover_img;
    var title = item.presentation;
    var view = item.views_count;
    var diseases = item.diseasesList;
    if (typeof item.notation !== "undefined" && item.notation!= "0") {
        var notation = item.notation;
        var notation_size = (parseFloat(item.average_rating) / 2) * 20;
        var notation_label = notation + "/10 noté x fois";
    } else {
        var notation = 0;
        var notation_size = 0;
        var notation_label = "Soyez le premier à noter cette maladie !";
    }

    var html = "";

    html += '<div class="block-cas " style="">';

    html += '   <span class="img "><a href="' + url + '" rel="nofollow" class="view-cas-link"><img src="' + img_url + '" alt="" height="100"></a></span>';

    html += '<div class="block-cas__content">'

    html += '   <div class="note-container" title="' + notation + '/10 noté x fois" data-notecas="' + item.id + '" data-noteurl onclick="window.location.href=\'' + url + '\'">Note ';
    html += '       <span data-note="' + notation + '" class="note-result" style="position:absolute;z-index:10;overflow:hidden;white-space:nowrap;width:' + notation_size + 'px">'; 
    html += '           <span class="note-full note"></span>';
    html += '           <span class="note-full note"></span>'
    html += '           <span class="note-full note"></span>'
    html += '           <span class="note-full note"></span>'
    html += '           <span class="note-full note"></span>'
    html += '       </span>';
    html += '       <span class="note-result">'; 
    html += '           <span class="note-empty note"></span>';
    html += '           <span class="note-empty note"></span>';
    html += '           <span class="note-empty note"></span>';
    html += '           <span class="note-empty note"></span>';
    html += '           <span class="note-empty note"></span>';
    html += '       </span>';
    html += '       <div class="cas__view">';
    html += '           <span style="float:right">' + view + '</span>'
    html += '           <img src="/images/icon-view.png" alt="vues" wodth="16" height="12" />';
    html += '       </div>';
    html += '   </div>';

    html += '   <a href="' + url + '" class="view-cas-link"><span class="intro" style="cursor:pointer">' + title + '</span></a>';

    html += '   <div class="maladies">';
    for (const disease in diseases) {
        html += '<a href="">' + disease + ' </a>';
    }
    html += '   </div>';

    html += '   <a rel="nofollow" href="' + url + '" class="block-cas__link btn btn-primary tw-rounded-none tw-py-4 block tw-text-[14px]">Voir le cas</a>';

    html += '</div>';

    html += '</div>'

    return html;
}
function template_search(item) { 
    if (item.name_fr) {
        var title = item.name_fr;
    } else {
        var title = item.diagnosis;
    }
    
    var url = item.url;
    if (item.cover_image) {
        var img_url = item.cover_image;
        var bg_size = "";
    } else {
        var img_url = "/css/zend/layouts/frontend/images-interface/capsule/icons/icon-disease.svg";
        var bg_size = "background-size: 90%;";
    }

    var html = "";

    html += '<div class="block-result " style="">';

    html += '   <div class="img " style="background-image: url(' + img_url + ');' + bg_size + '">';
    // html += '       <a href="' + url + '" rel="nofollow" class="view-cas-link"><img src="' + img_url + '" alt="" height="100"></a>';
    html += '   </div>';

    html += '   <div class="text">' + title + '</a></div>';

    html += '   <a href="' + url + '" class="link">voir le cas</a>';

    html += '</div>';

    return html;
}
